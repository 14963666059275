.markdown-body {
  box-sizing: border-box;
  min-width: 200px;
  max-width: 980px;
  margin: 0 auto;
  padding: 45px;
}

@media (max-width: 767px) {
  .markdown-body {
    padding: 15px;
  }
}

.touchable-table .rdt_TableBody .rdt_TableRow:nth-child(2n) {
  background-color: white;
}
.touchable-table .rdt_TableBody .rdt_TableRow:hover {
  background-color: #e6e6e6;
  cursor: pointer;
}
